import React from 'react';
import { graphql } from 'gatsby';

import WorkPage from '../pages/projects/index';

const TaggedWorkPage = ({ pageContext, data }) => (
  <WorkPage pageContext={pageContext} data={data} />
);

export default TaggedWorkPage;

export const tagPageQuery = graphql`
  query TagsQuery($tag: String) {
    tags: allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    projects: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___title] }
      filter: { frontmatter: { templateKey: { eq: "case-study" }, tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            thumbnail
            tags
          }
        }
      }
    }
  }
`;
